import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    // メイン画像の切り替え
    $(function () {
      $('.js-switch-main-image').on('click', function () {
        $('.p-medicine-prescription__main__section__top__image__sub__each').css('outline', 'none')
        $(this).css('outline', 'solid 2px #9778d6')
        $('.p-medicine-prescription__main__section__top__image__main').attr('src', this.src)
      })
    })
  }
}
