import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['link', 'accodion', 'topBtn', 'scrollPoint', 'bodySection']
  connect() {
    // アンカーリンクの遷移、スムーススクロール処理
    // 基準点の準備
    this.scrollPointList = []
    this.links = $(this.linkTargets)
    this.accodions = $(this.accodionTargets)
    this.topBtn = $(this.topBtnTarget)
    this.scrollPoints = $(this.scrollPointTargets)
    this.bodySection = $(this.bodySectionTarget)
    this.accodionInit()

    // スクロール位置の調整
    const pcAdjust = 30 // PC用
    const spAdjust = -40 // SP用
    this.adjust = 0

    //リサイズされたときの処理
    let resizeTimeoutID = 0
    let resizeDelay = 0
    let isPcView = window.innerWidth > 999.98
    let tmpIsPcView = null
    $(window).on('resize', () => {
      // リサイズイベントを常時実行しないようにする
      clearTimeout(resizeTimeoutID)
      resizeTimeoutID = setTimeout(() => {
        this.PositionCheck()
        isPcView = window.innerWidth > 999.98
        this.adjust = isPcView ? pcAdjust : spAdjust

        // PCサイズからSPサイズに変更 or SPサイズからPCサイズに変更されたとき
        if (isPcView !== tmpIsPcView) {
          this.accodionInit()
        }

        // 現在のview状態を保持する
        tmpIsPcView = isPcView
      }, resizeDelay)
    })

    $(window).on('load scroll', () => {
      // TOPへ戻るボタンレイアウト調整
      const windowHeight = $(window).height()
      const contentHeight = $('html').height()
      const footerHeight = $('footer').height()
      const targetHeight = contentHeight - windowHeight - footerHeight + 48
      if ($(window).scrollTop() > 200) {
        this.topBtn.addClass('is-active')
      } else {
        this.topBtn.removeClass('is-active')
      }
      if ($(window).scrollTop() > targetHeight) {
        const topBtnPos = $(window).scrollTop() - targetHeight
        this.topBtn.css('bottom', 24 + topBtnPos)
      } else {
        this.topBtn.css('bottom', 24)
      }

      // TOP 検索ボックス
      const currentYOffset = window.pageYOffset
      this.adjust = window.innerWidth > 999.98 ? pcAdjust : currentYOffset < 94 ? spAdjust : spAdjust + 40
      this.PositionCheck()
      this.ScrollAnimation()
    })
  }

  // ページ内ナビゲーションをクリックした際のスムーススクロール
  linkClick(event) {
    let elmHash
    // 警告ボタンの中をクリックすると中の要素が呼ばれるので親のhrefを使う
    if ($(event.target).attr('href')) {
      elmHash = $(event.target).attr('href')
    } else {
      elmHash = $(event.target).parent().attr('href')
    }
    if (elmHash !== '#overview' && this.bodySection.hasClass('is-limited')) {
      return false
    }
    const headerH = $('.js-shared-header').outerHeight(true) ?? 0
    const pos = Math.round($(elmHash).offset().top - headerH)
    $('body,html').animate({ scrollTop: pos - this.adjust }, 500)
    return false
  }

  accodionClick(event) {
    const device = window.innerWidth > 999.98 ? 'PC' : 'SP'
    if (device === 'PC') return
    if (this.bodySection.hasClass('is-limited')) return
    $(event.target).toggleClass('is-open')
    $(event.target).next().slideToggle()
  }

  topBtnClick() {
    $('body,html').animate({ scrollTop: 0 }, 500)
  }

  // ナビゲーションに現在地のクラスを付与する
  ScrollAnimation() {
    const device = window.innerWidth > 999.98 ? 'PC' : 'SP'
    const scroll = Math.round($(window).scrollTop()) + this.adjust
    this.links.removeClass('current')

    if (device === 'PC') {
      const NavElem = this.links
      const windowHeight = $(window).height()
      const contentHeight = $('.p-medicine').height()
      this.scrollPointList.forEach((elm, idx) => {
        if (idx == 0 && scroll >= 0 && scroll < elm) {
          this.links.removeClass('current')
        } else if (scroll >= elm && scroll < this.scrollPointList[idx + 1]) {
          if (!$(NavElem[idx]).hasClass('disabled')) {
            $(NavElem[idx]).addClass('current')
          }
        } else if (
          idx == this.scrollPointList.length - 1 &&
          scroll >= this.scrollPointList[this.scrollPointList.length - 1]
        ) {
          if (!$(NavElem[idx]).hasClass('disabled')) {
            $(NavElem[idx]).addClass('current')
          }
        } else if ($(window).scrollTop() > contentHeight - windowHeight) {
          this.links.removeClass('current')
          if (!$(NavElem[NavElem.length - 1]).hasClass('disabled')) {
            $(NavElem[NavElem.length - 1]).addClass('current')
          }
        }
      })
    }
  }

  // ターゲット要素の位置を取得する
  PositionCheck() {
    let scrollPointList = []
    // headerの高さを取得
    const headerH = $('.js-shared-header').outerHeight(true) ?? 0
    // スクロール先のエリアの位置を取得
    this.scrollPoints.each(function () {
      scrollPointList.push(Math.round(parseInt($(this).offset().top - headerH)))
    })
    this.scrollPointList = scrollPointList
  }

  // アコーディオン初期表示
  accodionInit() {
    this.accodions.each(function (idx) {
      const device = window.innerWidth > 999.98 ? 'PC' : 'SP'
      if (device === 'PC') {
        $(this).css('display', 'block')
      } else {
        if (idx === 0) {
          $(this).prev().addClass('is-open')
          return
        } else {
          $(this).prev().removeClass('is-open')
        }
        $(this).css('display', 'none')
      }
    })
  }
}
